import {
  faCalendarAlt,
  faCircleCheck,
  faExclamationTriangle,
  faImage,
  faInfoCircle,
  faMagnifyingGlass,
  faMapLocation,
  faPerson,
  faPersonFallingBurst,
  faSave,
  faSquareEnvelope,
  faTriangleExclamation,
  faUserTie,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { toast } from "react-toastify";
import { Col, Row } from "reactstrap";
import { Countries } from "../../../data/countries";
import { ChangeTypes, IncidentStatus } from "../../../data/enums";
import { ConvertUTCDateToLocalDate } from "../../../data/helpers";
import { States } from "../../../data/states";
import { getUserInfo } from "../../../services/auth";
import {
  GetDictionaryItem,
  GetIncidentForm,
  GetIncidentTypeDropdowns,
  SubmitIncidentForm,
} from "../../../services/incidentReport";
import Center from "../../atoms/center";
import FormContainer from "../../atoms/formContainer";
import FormDropdown from "../../atoms/formDropdown";
import FormField from "../../atoms/formField";
import FormFieldDatePicker from "../../atoms/formFieldDatePicker";
import FormFieldQuestion from "../../atoms/formFieldQuestion";
import FormFieldRadioQuestion from "../../atoms/formFieldRadioQuestion";
import FormFieldType from "../../atoms/formFieldType";
import FormHeader from "../../atoms/formHeader";
import FormHeaderTitle from "../../atoms/formHeaderTitle";
import Hr from "../../atoms/hr";
import Label from "../../atoms/label";
import Loading from "../../atoms/loading";
import PageMessage from "../../atoms/pageMessage";
import Required from "../../atoms/required";
import SelectMultiple from "../../atoms/selectMultiple";
import SubmitButton from "../../atoms/submitButton";
import ChangeLog from "../../molecules/changeLog";
import Dictionary from "../../molecules/dictionaryLookup";
import FormFieldUploadFile from "../../molecules/uploadFile";
import UserCard from "../../molecules/userCard";
import UserSearch from "../../molecules/userSearch";
import Blog from "../blog";
import InvestigationReportCAPA from "./investigationReport";
import ManagerIncidentClose from "./managerCloseout";
import TypeEnvironmental from "./typeEnvironmental";
import TypeFirstAid from "./typeFirstAid";
import TypeHazardID from "./typeHazardId";
import TypeInjury from "./typeInjury";
import TypeNearMiss from "./typeNearMiss";
import TypePropertyDamage from "./typePropertyDamage";
import TypeVehicularAccident from "./typeVehicularAccident";

const initialState = {
  ID: null,
  Name: "",
  Email: "",
  EmployeeNumber: "",
  IncidentNumber: "",
  UserLocation: "",
  Department: "",
  GUID: "",
  Type: 0,
  Status: 0,
  CAPANeeded: false,
  isNearMissSevere: "",
  TypeFormValues: [],
  NatureInjury: "",
  LocationOther: "",
  LocationAddress: "",
  LocationCity: "",
  LocationState: "",
  LocationCountry: "",
  Location: "",
  LocationID: 0,
  LocationOtherType: 0,
  QWhatEmployeeDoing: "",
  QHowDidOccur: "",
  QListActions: "",
  QSomeoneElseUser: "",
  QAManagerNotified: null,
  QStillAnIssue: null,
  QWorkRelated: null,
  QSomeoneElse: null,
  OneSourceAccount: false,
  QLocationDesc: "",
  typeDef: "",
  loading: true,
  IncidentDate: new Date(),
  ManagerConfirmedDate: "",
  ManagerConfirmedDateChecked: "",
  ModifiedBy: "",
  allTypes: [],
  allLocations: [],
  allBaseTypes: [],
  isReadOnly: false,
  showWarning: false,
  AtHome: null,
  CCUsers: [],
  AdminUsers: [],
  usersEdit: false,
  canEdit: false,
};

export default class IncidentTypeForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = initialState;
  }

  componentDidMount() {
    this.populateUserInfo();
    GetIncidentTypeDropdowns().then((res) => {
      this.setState(
        {
          allTypes: res.Types,
          allLocations: res.Locations,
          allOneSourceLocations: res.LocationsOneSource,
          allBaseTypes: res.BaseTypes,
        },
        () => {
          this.setState(
            { GUID: this.props.guid, IncidentNumber: this.props.number },
            () => {
              this.serialNumberSearch();
            }
          );
        }
      );
    });
  }

  handleTimeInput = (date) => {
    if (date) {
      this.setState({ IncidentDate: new Date(date) });
    } else {
      this.setState({ IncidentDate: new Date() });
    }
  };

  populateUserInfo = () => {
    var savedUser = getUserInfo();
    if (savedUser !== "") {
      this.setState({
        EmployeeType: savedUser.EmployeeType,
        Name: savedUser.Name,
        Email: savedUser.Email,
        EmployeeNumber: savedUser.EmployeeNumber,
        ManagerEmail: savedUser.ManagerEmail,
        BusinessUnit: savedUser.BusinessUnit,
        OneSourceAccount: savedUser.BusinessUnit === "1",
        Account: savedUser.Account,
        Department: savedUser.Department,
        UserLocation: savedUser.UserLocation,
        SSO: savedUser.SSO,
      });
    }
  };

  clear = () => {
    this.setState(initialState);
    if (typeof window !== "undefined") {
      document?.getElementsByTagName("form")[0].removeAttribute("class");
    }
  };

  loadTypeDef = (item) => {
    GetDictionaryItem(item).then((res) => {
      this.setState({
        typeDef: (
          <>
            <b>{item}:&nbsp;</b>
            {res}
          </>
        ),
      });
    });
  };

  handleUserInput = (event, key = ChangeTypes.NONE) => {
    const name = event.target.name;
    const value = event.target.value;

    if (name === "Type") {
      var type = this.state.allBaseTypes?.filter(
        (x) => x.value.toString() === value
      );
      if (type.length > 0) {
        this.setState({ typeName: type[0].key, TypeFormValues: [] });
      }
      if (this.state.ID !== null) {
        this.setState({
          showWarning: true,
          CAPANeeded: value === "1",
        });
      }
    }
    if (name === "AtHome") {
      if (value === "yes") {
        this.setState({
          LocationOtherType: "Home Address",
          LocationID: 3,
          Location: 3,
        });
      } else {
        this.setState({
          LocationOtherType: 0,
          Location: "",
          LocationID: 0,
        });
      }
    }
    switch (key) {
      case ChangeTypes.UPPER:
        this.setState({ [name]: value.toUpperCase().trim() });
        break;
      case ChangeTypes.NONE:
        this.setState({ [name]: value.trim() });
        break;
      case ChangeTypes.CHECK:
        if (name === "OneSourceAccount") {
          this.setState({ LocationID: 0, Location: "" });
        }
        this.setState({ [name]: value === "yes" });
        break;
      case ChangeTypes.CUSTOM:
        this.setState({ [name]: event.target.checked });
        break;
      case ChangeTypes.NOTES:
      default:
        this.setState({ [name]: value });
        break;
    }
  };
  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ loading: true }, () => {
      this.validateForm();
    });
  };

  validateForm = () => {
    let isValid = true;
    if (this.state.Type === 0) {
      toast.error("Incident Type is required.");
      isValid = false;
    }
    if (this.state.LocationID === 0) {
      toast.error("Address is Required.");
      isValid = false;
    }
    if (
      this.state.LocationID.toString() === "3" &&
      this.state.LocationOtherType.toString() === "0"
    ) {
      toast.error("Location Type is Required.");
      isValid = false;
    }
    if (
      this.state.LocationID.toString() === "3" &&
      this.state.LocationCountry === ""
    ) {
      toast.error("Country is Required.");
      isValid = false;
    }

    if (
      this.state.LocationID.toString() === "3" &&
      this.state.LocationState === ""
    ) {
      toast.error("State is Required.");
      isValid = false;
    }

    if (this.state.TypeFormValues.length === 0) {
      toast.error("Incident Type Questions are Required.");
      isValid = false;
    }

    if (this.state.TypeFormValues.length !== 0) {
      if (this.state.TypeFormValues?.PartOfBody?.toString() === "0") {
        toast.error("Part of The Body Affected is Required.");
        isValid = false;
      }
      if (this.state.TypeFormValues?.InjuryType?.toString() === "0") {
        toast.error("Injury Type is Required.");
        isValid = false;
      }
      if (
        this.state.Type.toString() === "1" &&
        this.state.TypeFormValues?.QSeverityType?.toString() === "0"
      ) {
        toast.error("Injury Severity is Required.");
        isValid = false;
      }
      if (this.state.TypeFormValues?.QMechanismType?.toString() === "0") {
        toast.error("Mechanism Type is Required.");
        isValid = false;
      }
      if (this.state.TypeFormValues?.HazardIDType?.toString() === "0") {
        toast.error("Hazard-Id Type is Required.");
        isValid = false;
      }
      if (
        this.state.Type.toString() !== "1" &&
        this.state.TypeFormValues?.QSeverityType?.toString() === "0"
      ) {
        toast.error("Near Miss Severity is Required.");
        isValid = false;
      }
      if (this.state.TypeFormValues?.NearMissType?.toString() === "0") {
        toast.error("Near Miss Type is Required.");
        isValid = false;
      }
      if (this.state.TypeFormValues?.TransportationType?.toString() === "0") {
        toast.error("Vehicular Incident Type is Required.");
        isValid = false;
      }

      if (this.state.TypeFormValues?.PropertyType?.toString() === "0") {
        toast.error("Property Type is Required.");
        isValid = false;
      }

      if (this.state.TypeFormValues?.QType?.toString() === "0") {
        toast.error("Transportation Type is Required.");
        isValid = false;
      }
      if (this.state.TypeFormValues?.EnvironmentalType?.toString() === "0") {
        toast.error("Environmental Type is Required.");
        isValid = false;
      }
    }

    if (isValid) {
      this.submitForm();
    } else {
      this.setState({ loading: false });
    }
  };

  submitForm() {
    this.setState(
      {
        loading: true,
        ModifiedBy: this.state.ID !== null ? getUserInfo().Email : null,
        ManagerConfirmedDateChecked:
          this.props.isManager || this.props.isEHS || this.state.canEdit,
      },
      () => {
        if (typeof window !== "undefined") {
          window.scrollTo(0, 0);
        }
        SubmitIncidentForm({ formData: this.state })
          .then((res) => {
            if (res.data.HasErrors) {
              res.data.ErrorList.forEach((item) => {
                toast.error(item);
              });
            } else {
              if (
                this.props.isManager ||
                this.props.isEHS ||
                this.state.canEdit
              ) {
                this.setState(res.data, () => {
                  this.setState({ usersEdit: false });
                  toast.success(
                    "Ticket has been been confirmed, Please complete the Manager Closeout/Safety Alert"
                  );

                  const section = document.querySelector("#manager");
                  if (section !== null) {
                    setTimeout(function () {
                      section.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                      });
                    }, 1000);
                  }
                });
              } else {
                toast.success("Ticket has been created/updated Successfully");

                this.props.updateTab(3);
              }

              this.setState({ loading: false });
            }
          })
          .catch((error) => {
            toast.error(error);
            this.setState({ loading: false });
          });
      }
    );
  }

  updateFile = (type, val) => {};

  updateTypeQuestions = (state) => {
    this.setState({ TypeFormValues: state });
  };

  updateNearMiss = (key) => {
    this.setState({
      isNearMissSevere: key,
      CAPANeeded: (this.state.Type === 2 && key) || this.state.Type === 1,
    });
  };

  serialNumberSearch = (search = "") => {
    this.setState({
      loading: true,
    });
    var payload = {
      Limit: 1,
      Offset: 0,
      SN: search !== "" ? search : this.state.GUID,
      Email: "",
      IsManager: false,
    };
    GetIncidentForm(payload)
      .then((res) => {
        if (res.err || typeof res === "string") {
          this.setState({
            loading: false,
          });
          return;
        } else {
          res.IncidentDate = ConvertUTCDateToLocalDate(
            new Date(res.IncidentDate)
          );
          var user = getUserInfo().Email;
          this.setState(
            {
              canEdit:
                res.ManagerEmail.toLowerCase() === user.toLowerCase() ||
                res.AdminUsers.includes(user.toLowerCase()),
              ...res,
            },
            () => {
              var type = this.state.allBaseTypes?.filter(
                (x) => x.value.toString() === res.Type.toString()
              );
              var location = this.state.allLocations.filter(
                (x) => x.key === res.LocationID
              );
              if (location.length === 0) {
                location = this.state.allOneSourceLocations.filter(
                  (x) => x.key === res.LocationID
                );
              }

              this.setState({
                loading: false,
                usersEdit: false,
                typeName: type.length > 0 ? type[0].key : "",
                isReadOnly: this.state.Status > 1 && !this.props.isEHS,
                AtHome: this.state.LocationOtherType === "Home Address",
                Location: location[0],
                OneSourceAccount: this.state.allOneSourceLocations.includes(
                  location[0]
                ),
                CAPANeeded:
                  (this.state.Type === 2 && this.state.isNearMissSevere) ||
                  this.state.Type === 1,
              });
              if (typeof window !== "undefined") {
                window.scrollTo(0, 0);
              }
            }
          );
        }
      })
      .catch((err) => {
        toast.error("An Error has Occurred");
      });
  };
  changeLocation = (item) => {
    this.setState({
      Location: item,
      LocationID: item !== null ? item.value : 0,
    });
  };

  changeCountry = (item) => {
    if (item) {
      this.setState({
        LocationCountry: item.value,
      });
    }
  };

  changeState = (item) => {
    if (item) {
      this.setState({
        LocationState: item.value,
      });
    }
  };

  changeType = (item) => {
    if (item) {
      var value = item.value.toString();
      var type = this.state.allBaseTypes?.filter(
        (x) => x.value.toString() === value
      );
      if (type.length > 0) {
        this.setState({ typeName: type[0].key, TypeFormValues: [] });
      }
      if (this.state.ID !== null) {
        this.setState({
          showWarning: true,
          CAPANeeded: value === "1",
        });
      }
      this.setState({
        Type: item.value,
      });
    }
  };

  addCCUser = (user) => {
    this.setState({ CCUsers: [...this.state.CCUsers, user], usersEdit: true });
  };

  addSomeoneElseUser = (user) => {
    this.setState({ QSomeoneElseUser: "" }, () => {
      this.setState({ QSomeoneElseUser: user });
    });
  };

  RemoveCCUser = (user) => {
    var a = this.state.CCUsers;
    this.setState({ CCUsers: [] }, () => {
      this.setState({
        CCUsers: a.filter((ccUser) => ccUser !== user),
        usersEdit: true,
      });
    });
  };

  addAdminUser = (user) => {
    this.setState({
      AdminUsers: [...this.state.AdminUsers, user],
      usersEdit: true,
    });
  };

  RemoveAdminUser = (user) => {
    var a = this.state.AdminUsers;
    this.setState({ AdminUsers: [] }, () => {
      this.setState({
        AdminUsers: a.filter((ccUser) => ccUser !== user),
        usersEdit: true,
      });
    });
  };

  render() {
    var type = this.state.allBaseTypes?.filter(
      (x) => x.value.toString() === this.state.Type.toString()
    );
    var status = IncidentStatus?.filter(
      (x) => x.value.toString() === this.state.Status.toString()
    );

    var ccUsers = this.state.CCUsers?.map((item, key) => (
      <Col xs="12" md="6">
        <UserCard
          remove={() => this.RemoveCCUser(item)}
          user={item}
          readOnly={this.state.isReadOnly}
          key={key}
        ></UserCard>
      </Col>
    ));
    var adminUsers = this.state.AdminUsers?.map((item, key) => (
      <Col xs="12" md="6">
        <UserCard
          remove={() => this.RemoveAdminUser(item)}
          readOnly={this.state.isReadOnly}
          user={item}
          key={key}
        ></UserCard>
      </Col>
    ));

    return (
      <>
        {(this.props.isManager || this.state.canEdit) && (
          <PageMessage>
            <FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon>
            &nbsp; Please review the following ticket. If any changes are
            required please reach out to the submitter to confirm the changes.
          </PageMessage>
        )}
        <FormContainer>
          <FormHeaderTitle isEHS={this.props.isEHS}>
            <FontAwesomeIcon icon={faPersonFallingBurst}></FontAwesomeIcon>
            {this.state.IncidentNumber && (
              <>&nbsp;Ticket: {this.state.IncidentNumber} </>
            )}
            {!this.state.IncidentNumber && <>&nbsp;New Ticket</>}

            <br className="d-block d-md-none" />

            {type?.length > 0 && (
              <>
                <FormFieldType lg={true}>{type[0].key}</FormFieldType>
              </>
            )}
            {status.length > 0 && (
              <FormFieldType lg={true}>{status[0].label.trim()}</FormFieldType>
            )}
            {this.props.isEHS && (
              <FormFieldType lg={true}>EHS Rep</FormFieldType>
            )}
          </FormHeaderTitle>

          {!this.state.loading && (
            <form id="form" onSubmit={this.handleSubmit} autocomplete="off">
              <Row>
                <Col xs={{ size: 12, order: 2 }} md={{ size: 6, order: 1 }}>
                  <FormHeader>
                    <FontAwesomeIcon icon={faCalendarAlt}></FontAwesomeIcon>
                    &nbsp;When did Incident Occur{" "}
                  </FormHeader>
                  <FormFieldDatePicker
                    title="Date of Incident"
                    selected={this.state.IncidentDate}
                    onChange={this.handleTimeInput}
                    name="IncidentDate"
                    filter="General"
                    disabled={this.state.isReadOnly}
                    required={true}
                    timeIntervals={15}
                  ></FormFieldDatePicker>
                </Col>
                <Col xs={{ size: 12, order: 1 }} md={{ size: 6, order: 2 }}>
                  <UserCard
                    guest={this.state}
                    style={{ textAlign: "right" }}
                    user={this.state.Email}
                  ></UserCard>
                </Col>
              </Row>

              <FormHeader>
                <FontAwesomeIcon icon={faMapLocation}></FontAwesomeIcon>
                &nbsp;Where did Incident Occur
              </FormHeader>
              <FormFieldRadioQuestion
                title="Did the incident occur at Home?"
                filter={"General"}
                readOnly={this.state.isReadOnly}
                name="AtHome"
                value={this.state.AtHome}
                onChange={(event) =>
                  this.handleUserInput(event, ChangeTypes.CHECK)
                }
              />
              {this.state.AtHome === false && (
                <>
                  <FormFieldRadioQuestion
                    title="OneSource Account?"
                    filter={"General"}
                    readOnly={this.state.isReadOnly}
                    name="OneSourceAccount"
                    value={this.state.OneSourceAccount}
                    onChange={(event) =>
                      this.handleUserInput(event, ChangeTypes.CHECK)
                    }
                  />
                  {!this.state.OneSourceAccount && (
                    <SelectMultiple
                      placeholder={
                        "Find a PerkinElmer Location Address, City, or Country"
                      }
                      onChange={this.changeLocation}
                      single={true}
                      largeStyles={true}
                      options={this.state.allLocations}
                      disabled={this.state.isReadOnly}
                      value={this.state.Location}
                    />
                  )}
                  {this.state.OneSourceAccount && (
                    <SelectMultiple
                      placeholder={
                        "Find a OneSource Location Account Name, Address, or Country"
                      }
                      onChange={this.changeLocation}
                      single={true}
                      largeStyles={true}
                      options={this.state.allOneSourceLocations}
                      disabled={this.state.isReadOnly}
                      value={this.state.Location}
                    />
                  )}
                </>
              )}

              {(this.state.LocationID?.toString() === "3" ||
                this.state.LocationID?.toString() === "700" ||
                this.state.AtHome) && (
                <>
                  <FormDropdown
                    value={this.state.LocationOtherType}
                    onChange={this.handleUserInput}
                    stackLabel={true}
                    isValid={false}
                    name="LocationOtherType"
                    title="Location Type"
                    filter="General"
                    required={true}
                    readOnly={this.state.isReadOnly}
                    options={[
                      {
                        key: "Home Address",
                        value: "Home Address",
                        label: "Home Address",
                      },
                      {
                        key: "Customer Location",
                        value: "Customer Location",
                        label: "Customer Location (Not listed above)",
                      },
                      {
                        key: "In Transit",
                        value: "In Transit",
                        label: "In Transit",
                      },
                    ]}
                    dropdownContentType="Location Type"
                  ></FormDropdown>
                  <Hr />
                  <Row>
                    <Col xs="12" md="3">
                      <Label>
                        <b>Country</b>
                        <Required></Required>
                      </Label>
                    </Col>
                    <Col>
                      <SelectMultiple
                        placeholder={"Search for Country"}
                        onChange={this.changeCountry}
                        single={true}
                        largeStyles={true}
                        options={Countries}
                        disabled={this.state.isReadOnly}
                        value={Countries.find(
                          (x) => x.label === this.state.LocationCountry
                        )}
                      />
                    </Col>
                  </Row>
                  <Hr />
                  {this.state.LocationOtherType !== "Home Address" && (
                    <>
                      <FormField
                        title="Address"
                        value={this.state.LocationAddress}
                        filter="General"
                        disabled={this.state.isReadOnly}
                        smaller={true}
                        stackLabel={true}
                        onChange={(event) =>
                          this.handleUserInput(event, ChangeTypes.NOTES)
                        }
                        name="LocationAddress"
                        required={true}
                      ></FormField>
                      <FormField
                        title="City"
                        value={this.state.LocationCity}
                        filter="General"
                        smaller={true}
                        disabled={this.state.isReadOnly}
                        stackLabel={true}
                        onChange={(event) =>
                          this.handleUserInput(event, ChangeTypes.NOTES)
                        }
                        name="LocationCity"
                        required={true}
                      ></FormField>
                    </>
                  )}
                  {this.state.LocationCountry !== "United States" && (
                    <FormField
                      title="State / Province / Region"
                      value={this.state.LocationState}
                      disabled={this.state.isReadOnly}
                      smaller={true}
                      stackLabel={true}
                      onChange={(event) =>
                        this.handleUserInput(event, ChangeTypes.NOTES)
                      }
                      name="LocationState"
                      required={true}
                    ></FormField>
                  )}
                  {this.state.LocationCountry === "United States" && (
                    <Row>
                      <Col xs="12" md="3">
                        <Label>
                          <b>State</b>
                          <Required></Required>
                        </Label>
                      </Col>
                      <Col>
                        <SelectMultiple
                          placeholder={"Search for State"}
                          onChange={this.changeState}
                          single={true}
                          largeStyles={true}
                          options={States}
                          disabled={this.state.isReadOnly}
                          value={States.find(
                            (x) => x.label === this.state.LocationState
                          )}
                        />
                      </Col>
                    </Row>
                  )}
                  <Hr />
                </>
              )}
              <FormFieldQuestion
                question="Specific location of incident (shipping, manufacturing, entranceway, bathroom, etc.)"
                name="QLocationDesc"
                filter={"General"}
                value={this.state.QLocationDesc}
                required={true}
                readOnly={this.state.isReadOnly}
                maxLength={500}
                onChange={(event) =>
                  this.handleUserInput(event, ChangeTypes.NOTES)
                }
              ></FormFieldQuestion>
              <FormHeader>
                <FontAwesomeIcon icon={faPersonFallingBurst}></FontAwesomeIcon>
                &nbsp;Type of Incident
              </FormHeader>
              {this.state.showWarning && (
                <PageMessage style={{ color: "#ad0101" }}>
                  <FontAwesomeIcon
                    style={{
                      fontSize: "30px",
                      color: "#ad0101",
                      marginTop: "3px",
                    }}
                    className="fa-beat"
                    icon={faExclamationTriangle}
                  ></FontAwesomeIcon>
                  &nbsp; Warning: Changing "Type of Incident" after submission
                  requires the ticket to go back to manager review. Previous
                  manager reviews and CAPA investigations for this ticket will
                  be removed.
                </PageMessage>
              )}
              <SelectMultiple
                placeholder={"Choose a Type of Incident"}
                onChange={this.changeType}
                single={true}
                largeStyles={true}
                options={this.state.allBaseTypes}
                disabled={this.state.isReadOnly}
                value={this.state.allBaseTypes.find(
                  (x) => x.value === this.state.Type
                )}
              />
              <br />

              {this.state.typeName && (
                <Dictionary item={this.state.typeName}></Dictionary>
              )}

              <FormHeader>
                <FontAwesomeIcon icon={faMagnifyingGlass}></FontAwesomeIcon>
                &nbsp;Additional Details
              </FormHeader>
              <FormFieldQuestion
                question="Describe the situation when the incident occurred (i.e. what were you doing, what do you think contributed/caused the incident)"
                value={this.state.QHowDidOccur}
                readOnly={this.state.isReadOnly}
                minHeight={"160px"}
                filter={"General"}
                onChange={(event) =>
                  this.handleUserInput(event, ChangeTypes.NOTES)
                }
                name="QHowDidOccur"
                required={true}
                maxLength={1000}
              ></FormFieldQuestion>
              <FormFieldRadioQuestion
                title="Has the Direct Manager/Supervisor been notified?"
                filter={"General"}
                readOnly={this.state.isReadOnly}
                name="QAManagerNotified"
                required={true}
                value={this.state.QAManagerNotified}
                onChange={(event) =>
                  this.handleUserInput(event, ChangeTypes.CHECK)
                }
              />
              {this.state.Type.toString() === "1" && (
                <>
                  <TypeInjury
                    state={this.state.TypeFormValues}
                    guid={this.state.GUID}
                    readOnly={this.state.isReadOnly}
                    number={this.state.IncidentNumber}
                    update={this.updateTypeQuestions}
                    dropdowns={this.state.allTypes}
                    changeFirstAidType={this.changeType}
                  ></TypeInjury>
                </>
              )}
              {this.state.Type.toString() === "2" && (
                <>
                  <TypeNearMiss
                    state={this.state.TypeFormValues}
                    guid={this.state.GUID}
                    readOnly={this.state.isReadOnly}
                    number={this.state.IncidentNumber}
                    update={this.updateTypeQuestions}
                    updateNearMissType={this.updateNearMiss}
                    dropdowns={this.state.allTypes}
                  ></TypeNearMiss>
                </>
              )}
              {this.state.Type.toString() === "3" && (
                <>
                  <TypeEnvironmental
                    state={this.state.TypeFormValues}
                    guid={this.state.GUID}
                    readOnly={this.state.isReadOnly}
                    number={this.state.IncidentNumber}
                    update={this.updateTypeQuestions}
                    dropdowns={this.state.allTypes}
                  ></TypeEnvironmental>
                </>
              )}
              {this.state.Type.toString() === "4" && (
                <>
                  <TypePropertyDamage
                    state={this.state.TypeFormValues}
                    guid={this.state.GUID}
                    readOnly={this.state.isReadOnly}
                    number={this.state.IncidentNumber}
                    update={this.updateTypeQuestions}
                    dropdowns={this.state.allTypes}
                  ></TypePropertyDamage>
                </>
              )}
              {this.state.Type.toString() === "5" && (
                <>
                  <TypeVehicularAccident
                    state={this.state.TypeFormValues}
                    guid={this.state.GUID}
                    readOnly={this.state.isReadOnly}
                    number={this.state.IncidentNumber}
                    update={this.updateTypeQuestions}
                    dropdowns={this.state.allTypes}
                  ></TypeVehicularAccident>
                </>
              )}
              {this.state.Type.toString() === "6" && (
                <>
                  <TypeFirstAid
                    state={this.state.TypeFormValues}
                    guid={this.state.GUID}
                    readOnly={this.state.isReadOnly}
                    number={this.state.IncidentNumber}
                    update={this.updateTypeQuestions}
                    dropdowns={this.state.allTypes}
                  ></TypeFirstAid>
                </>
              )}
              {this.state.Type.toString() === "7" && (
                <>
                  <TypeHazardID
                    state={this.state.TypeFormValues}
                    guid={this.state.GUID}
                    readOnly={this.state.isReadOnly}
                    number={this.state.IncidentNumber}
                    update={this.updateTypeQuestions}
                    dropdowns={this.state.allTypes}
                  ></TypeHazardID>
                </>
              )}
              <FormFieldRadioQuestion
                title="Is the incident still prevalent and a risk to safety?"
                filter={"General"}
                readOnly={this.state.isReadOnly}
                name="QStillAnIssue"
                value={this.state.QStillAnIssue}
                required={true}
                onChange={(event) =>
                  this.handleUserInput(event, ChangeTypes.CHECK)
                }
              />
              <FormFieldRadioQuestion
                title="Was this a work-related incident?"
                filter={"General"}
                readOnly={this.state.isReadOnly}
                name="QWorkRelated"
                value={this.state.QWorkRelated}
                required={true}
                onChange={(event) =>
                  this.handleUserInput(event, ChangeTypes.CHECK)
                }
              />
              <FormFieldQuestion
                question="Corrective action the employee took to immediately address the incident?"
                filter={"General"}
                name="QCorrectiveAction"
                readOnly={this.state.isReadOnly}
                value={this.state.QCorrectiveAction}
                required={true}
                maxLength={500}
                onChange={(event) =>
                  this.handleUserInput(event, ChangeTypes.NOTES)
                }
              ></FormFieldQuestion>
              <FormFieldRadioQuestion
                title="Are you submitting this ticket on behalf of someone else?"
                filter={"General"}
                readOnly={this.state.isReadOnly}
                name="QSomeoneElse"
                value={this.state.QSomeoneElse}
                required={true}
                onChange={(event) =>
                  this.handleUserInput(event, ChangeTypes.CHECK)
                }
              />
              {this.state.QSomeoneElse && (
                <>
                  <Label>
                    Please select PerkinElmer User (Leave blank if not listed){" "}
                    <FormFieldType>General</FormFieldType>
                  </Label>
                  <UserSearch
                    readOnly={this.state.isReadOnly}
                    addUserAction={this.addSomeoneElseUser}
                  ></UserSearch>
                  {this.state.QSomeoneElseUser && (
                    <UserCard user={this.state.QSomeoneElseUser}></UserCard>
                  )}
                  <Hr />
                </>
              )}
              <FormHeader>
                <FontAwesomeIcon icon={faImage}></FontAwesomeIcon>
                &nbsp;Attachments -{" "}
                <span style={{ color: "#ba0000" }}>
                  (DO NOT UPLOAD MEDICAL FORMS)
                </span>
              </FormHeader>
              <FormFieldUploadFile
                callback={this.updateFile}
                readOnly={this.state.isReadOnly}
                title={"Attachments (10 MB size limit per file) "}
                id={this.state.GUID}
                formType={1}
              ></FormFieldUploadFile>
              <Hr />
              <FormHeader>
                <FontAwesomeIcon icon={faPerson}></FontAwesomeIcon>
                &nbsp;Additional Witnesses to the Incident (if applicable)&nbsp;
                <span
                  style={{
                    fontSize: "14px",
                    color: "gray",
                    fontStyle: "italic",
                  }}
                >
                  - Users will get email updates and be able to view ticket
                </span>
              </FormHeader>
              {this.state.usersEdit && (
                <Center style={{ color: "#ba0000" }}>
                  <FontAwesomeIcon icon={faSave}></FontAwesomeIcon>{" "}
                  <b>Please Save Ticket to confirm user changes</b>
                </Center>
              )}
              <UserSearch
                readOnly={this.state.isReadOnly}
                addUserAction={this.addCCUser}
              ></UserSearch>
              <Row>{ccUsers}</Row>
              {ccUsers.length === 0 && (
                <Center>
                  <FontAwesomeIcon icon={faPerson}></FontAwesomeIcon>&nbsp; No
                  Users Have Been Added
                </Center>
              )}

              {(this.props.isManager ||
                this.props.isEHS ||
                this.state.canEdit) && (
                <>
                  <Hr />
                  <FormHeader>
                    <FontAwesomeIcon icon={faUserTie}></FontAwesomeIcon>
                    &nbsp;Assigned Admin / Manager Users&nbsp;(Optional)
                    <span
                      style={{
                        fontSize: "14px",
                        color: "gray",
                        fontStyle: "italic",
                      }}
                    >
                      &nbsp;- Users have the ability to edit and closeout ticket
                    </span>
                  </FormHeader>
                  {this.state.usersEdit && (
                    <Center style={{ color: "#ba0000" }}>
                      <FontAwesomeIcon icon={faSave}></FontAwesomeIcon>{" "}
                      <b>Please Save Ticket to confirm user changes</b>
                    </Center>
                  )}
                  <UserSearch
                    readOnly={this.state.isReadOnly}
                    addUserAction={this.addAdminUser}
                  ></UserSearch>
                  <Row>{adminUsers}</Row>
                  {adminUsers.length === 0 && (
                    <Center>
                      <FontAwesomeIcon icon={faPerson}></FontAwesomeIcon>&nbsp;
                      No Users Have Been Added
                    </Center>
                  )}
                  <FormHeader>
                    <FontAwesomeIcon icon={faUserTie}></FontAwesomeIcon>
                    &nbsp;Manager&nbsp;
                  </FormHeader>
                  <UserCard user={this.state.ManagerEmail}></UserCard>
                  <FormHeader>
                    <FontAwesomeIcon icon={faUserTie}></FontAwesomeIcon>
                    &nbsp;Manager Confirmation
                  </FormHeader>
                  {this.state.CAPANeeded && (
                    <PageMessage style={{ color: "#cd0000" }}>
                      <FontAwesomeIcon
                        icon={faTriangleExclamation}
                      ></FontAwesomeIcon>
                      &nbsp;CAPA Investigation is required for the this ticket.
                    </PageMessage>
                  )}
                  {this.state.Status === 1 &&
                    (this.props.isManager ||
                      this.props.isEHS ||
                      this.state.canEdit) && (
                      <b>
                        <FontAwesomeIcon
                          style={{
                            fontSize: "30px",
                            color: "#ad0101",
                            marginTop: "3px",
                          }}
                          className="fa-beat"
                          icon={faExclamationTriangle}
                        ></FontAwesomeIcon>
                        &nbsp; Please confirm that the above information is
                        correct, that the ‘Incident Type’ has been selected
                        correctly, and that the location details are filled out
                        accurately.
                      </b>
                    )}
                </>
              )}
              <Center>
                {(this.state.Status <= 1 || this.props.isEHS) && (
                  <SubmitButton
                    large={true}
                    disabled={this.state.isReadOnly}
                    text={
                      this.state.ID === null
                        ? "Submit Incident Report"
                        : (this.props.isManager ||
                            this.props.isEHS ||
                            this.state.canEdit) &&
                          this.state.Status === 1
                        ? "Confirm EHS Ticket"
                        : "Update EHS Ticket"
                    }
                    icon={
                      this.state.GUID === "" ? faSquareEnvelope : faCircleCheck
                    }
                    submitting={this.state.loading}
                  ></SubmitButton>
                )}
              </Center>
            </form>
          )}
          {this.state.loading && <Loading size="lg"></Loading>}
          <a
            style={{ marginTop: "-70px", position: "absolute" }}
            id="manager"
            href="#"
          >
            {" "}
          </a>
        </FormContainer>

        {!this.props.isNew && this.state.ManagerConfirmedDate && (
          <>
            {(this.props.isManager ||
              this.props.isEHS ||
              this.state.canEdit) && (
              <PageMessage>
                <FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon>
                &nbsp;Please Complete the following form within 7 days of ticket
                creation.
              </PageMessage>
            )}
            <ManagerIncidentClose
              typeName={type}
              disable={!this.props.isManager && !this.state.canEdit}
              isEHS={this.props.isEHS}
              capa={this.state.CAPANeeded}
              updateTab={this.props.updateTab}
              guid={this.props.guid}
            ></ManagerIncidentClose>
            {(this.state.Status === 2 || this.state.Status === 3) && (
              <>
                {(this.state.Type === 1 ||
                  (this.state.Type === 2 && this.state.isNearMissSevere)) && (
                  <>
                    <PageMessage>
                      <FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon>
                      &nbsp;Please Complete the following Investigation Report
                      within 30 days of ticket creation.
                    </PageMessage>
                    {(this.state.Type === 1 ||
                      (this.state.Type === 2 &&
                        this.state.isNearMissSevere)) && (
                      <InvestigationReportCAPA
                        typeName={type}
                        capa={this.state.CAPANeeded}
                        disable={!this.props.isManager && !this.state.canEdit}
                        isEHS={this.props.isEHS}
                        guid={this.props.guid}
                        updateTab={this.props.updateTab}
                      ></InvestigationReportCAPA>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}

        {this.state.GUID && (
          <FormContainer minHeight="0">
            <Blog
              blogType={1}
              sn={this.state.GUID}
              title="Additional Ticket Information"
            ></Blog>
          </FormContainer>
        )}

        {this.state.GUID && this.state.IncidentNumber && (
          <FormContainer minHeight="0">
            <ChangeLog
              guid={this.state.GUID}
              incident={this.state.IncidentNumber}
            ></ChangeLog>
          </FormContainer>
        )}
      </>
    );
  }
}
